import { useFlags } from 'launchdarkly-react-client-sdk';
import { Maybe, range } from '@tellurian/ts-utils';
import {
  ConnectorConfigurationPropertyValue,
  ConnectorPropertyDisplayType,
} from '../../../../generated/graphql';
import { ExtendedConnectorProperty } from '../../lib';

type ConnectorPropertyFormValues = Record<string, Maybe<string>>;

export type PropertyValueInitializer = (
  formValues: ConnectorPropertyFormValues,
  properties: ExtendedConnectorProperty[],
  propertyValues: ConnectorConfigurationPropertyValue[],
) => ConnectorPropertyFormValues;

export const generateDummyPassword = (length = 10) =>
  String.fromCharCode(...range(0, length).map(() => range(33, 126).random()));

const DummyPassword = generateDummyPassword();

export const PrimaryPropertyValueInitializer: PropertyValueInitializer = (
  formValues,
  properties,
  propertyValues,
): ConnectorPropertyFormValues => {
  return properties.reduce((res, prop) => {
    const entry = propertyValues.find(({ name }) => name === prop.name);
    return {
      ...res,
      [prop.name]: entry
        ? entry.value
        : prop.displayType === ConnectorPropertyDisplayType.Password
          ? DummyPassword
          : undefined,
    };
  }, formValues);
};

// Add custom initializers here
export const getDefaultValueInitializers = (
  _: ReturnType<typeof useFlags>,
): PropertyValueInitializer[] => [
  formValues => {
    if (!formValues['phone_number']) {
      formValues['phone_number'] = '';
    }

    return formValues;
  },
];
