export enum Key {
  Tab = 'Tab',
  Enter = 'Enter',
  Shift = 'Shift',
  Ctrl = 'Control',
  Escape = 'Escape',
  Space = ' ',
  End = 'End',
  Home = 'Home',
  Backspace = 'Backspace',
  Delete = 'Delete',

  LeftArrow = 'ArrowLeft',
  UpArrow = 'ArrowUp',
  RightArrow = 'ArrowRight',
  DownArrow = 'ArrowDown',

  F2 = 'F2',
}
