import React from 'react';
import Tooltip, { Placement, Trigger } from '../../components/ui/Tooltip';
import styles from './Avatar.module.css';

type AvatarProps = {
  altPart?: string;
  name?: string | null;
  src?: string | null;
  size?: number;
  tooltipContent?: React.ReactNode;
};

const RenderAvatar: React.FC<
  Pick<AvatarProps, 'src' | 'altPart' | 'name'> & {
    innerRef?: React.LegacyRef<HTMLImageElement>;
  }
> = ({ src, altPart, name, innerRef }) => {
  if (src) {
    return <img ref={innerRef} alt={altPart} className={styles.avatarImg} src={src} />;
  }
  return (
    <div ref={innerRef} className={styles.avatarPlaceholder}>
      <span className={styles.initials}>{name && name.substring && name.substring(0, 1)}</span>
    </div>
  );
};

export function Avatar({ src, altPart, name, size = 50, tooltipContent }: AvatarProps) {
  return (
    <div className={styles.container} style={{ height: size, width: size }}>
      {tooltipContent ? (
        <Tooltip
          content={tooltipContent}
          trigger={Trigger.HOVER}
          placement={Placement.Bottom}
          fixedPlacement
        >
          {ref => <RenderAvatar innerRef={ref} src={src} altPart={altPart} name={name} />}
        </Tooltip>
      ) : (
        <RenderAvatar src={src} altPart={altPart} name={name} />
      )}
    </div>
  );
}
